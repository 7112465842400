import {TFunction} from '@wix/yoshi-flow-editor'
import {DropdownOptionProps} from 'wix-ui-tpa'
import {isDonationTicketDefinition, hasPricingOptions, getPricingOptions} from '@wix/wix-events-commons-statics'
import {Element, SeatingPlan, Type} from '@wix/ambassador-seating-v1-seating-plan/types'
import {State} from '../../types'
import {getTicketPriceText} from '../order-success'
import {getPlaceTicketPriceText} from './place'

export const DEFAULT_OPTION_ID = 'all'
export const DONATION_OPTION_ID = 'Donation'

export const getSelectedPrice = (state: State) => state.seating.filters.selectedPrice

export const getSelectedZone = (state: State) => state.seating.filters.selectedZone

export const getDefaultOption = (t: TFunction) => ({id: 'all', value: t('seatings_filters_all'), isSelectable: true})

export const getPriceOptions = (
  tickets: wix.events.ticketing.TicketDefinition[],
  t: TFunction,
): DropdownOptionProps[] => [
  getDefaultOption(t),
  ...tickets
    .flatMap(ticket => {
      if (isDonationTicketDefinition(ticket)) {
        return {
          id: DONATION_OPTION_ID,
          value: getPlaceTicketPriceText(ticket, t),
          sortValue: '0',
          isSelectable: true,
        }
      } else if (hasPricingOptions(ticket)) {
        return getPricingOptions(ticket).map(({price}) => ({
          id: price.amount,
          value: getTicketPriceText(price, t),
          sortValue: price.value,
          isSelectable: true,
        }))
      } else {
        return {
          id: ticket.price.amount,
          value: getPlaceTicketPriceText(ticket, t),
          sortValue: ticket.price.value,
          isSelectable: true,
        }
      }
    })
    .filter(
      ({value}, index, allPrices) => allPrices.findIndex(({value: searchValue}) => value === searchValue) === index,
    )
    .sort((a, b) => Number(a.sortValue) - Number(b.sortValue)),
]

export const getZoneOptions = (plan: SeatingPlan, t: TFunction): DropdownOptionProps[] => {
  const zoneTypes = [
    {types: [Type.ROW], zoneTypeTranslation: 'seatings_filters_rows', itemTranslation: 'seatings_filters_row'},
    {
      types: [Type.TABLE, Type.ROUND_TABLE],
      zoneTypeTranslation: 'seatings_filters_tables',
      itemTranslation: 'seatings_filters_table',
    },
    {types: [Type.AREA], zoneTypeTranslation: 'seatings_filters_areas', itemTranslation: 'seatings_filters_area'},
  ]

  const allElements = plan.sections.flatMap(sector => sector.elements)

  return [
    getDefaultOption(t),
    ...zoneTypes.flatMap(({types, zoneTypeTranslation, itemTranslation}) => {
      const typeElements: Element[] = allElements.reduce((acc, curr) => {
        if (types.includes(curr.type) && !acc.find(item => item.title === curr.title)) {
          acc.push(curr)
        }

        return acc
      }, [])

      const typeElementsCount = typeElements.length

      if (!typeElementsCount) {
        return []
      }

      return [
        {
          id: types.join(','),
          value: t(zoneTypeTranslation, {count: typeElementsCount}),
          isSectionTitle: true,
          isSelectable: false,
        },
        ...typeElements
          .map(element => ({
            id: `${element.type}_${element.title}`,
            value: t(itemTranslation, {title: element.title, interpolation: {escapeValue: false}}),
            sortValue: element.title,
            isSelectable: true,
            isSectionTitle: false,
          }))
          .sort((a, b) => Number(a.sortValue) - Number(b.sortValue)),
      ]
    }),
  ]
}

export const getSelectedPriceValue = (priceOptions: DropdownOptionProps[], selectedPriceOptionId: string) =>
  priceOptions.find(({id}) => id === selectedPriceOptionId).value

export const getSelectedZoneValue = (zoneOptions: DropdownOptionProps[], selectedZoneOptionId: string) =>
  zoneOptions.find(({id}) => id === selectedZoneOptionId).value

export const getSelectedPriceOptionId = (state: State, priceOptions: DropdownOptionProps[]) => {
  const selectedPrice = getSelectedPrice(state)
  return (selectedPrice && priceOptions.find(price => price.id === selectedPrice)?.id) ?? DEFAULT_OPTION_ID
}

export const getSelectedZoneOptionId = (state: State, zoneOptions: DropdownOptionProps[]) => {
  const selectedZone = getSelectedZone(state)
  return (selectedZone && zoneOptions.find(element => element.id === selectedZone)?.id) ?? DEFAULT_OPTION_ID
}
