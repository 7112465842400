import {getFormattedMoney} from '@wix/wix-events-commons-statics'
import {TFunction} from '@wix/yoshi-flow-editor'

export const getInvoiceItemsWithTicketInfo = (
  items: wix.events.ticketing.Item[],
  tickets: wix.events.ticketing.Ticket[],
) =>
  items.map(item => ({
    ...item,
    ticket: tickets.find(ticket => ticket.ticketDefinitionId === item.id),
  }))

export const getTicketPriceText = (price: wix.events.Money, t: TFunction) =>
  Number(price.amount) ? getFormattedMoney(price) : t('ticketPrice.free')

export const getPlaceInfoFromTicket = (ticket: wix.events.ticketing.Ticket) => ({
  area: ticket?.ticketDetails?.areaName,
  table: ticket?.ticketDetails?.tableName,
})
