import {hasPricingOptions, saleScheduled} from '@wix/wix-events-commons-statics'
import {TFunction} from '@wix/yoshi-flow-editor'
import {Place} from '@wix/ambassador-seating-v1-seating-plan/types'
import {createAsyncAction} from '../../services/redux-toolkit'
import {isChooseSeatMode} from '../../selectors/navigation'
import {getAnyPlaceByPlanPlaceId, getPlace, getPlaces, getSelectedPlace} from '../../selectors/seating/places'
import {
  getPlaceTicketPriceText,
  getTotalPlaceQuantity,
  isPlaceArea,
  isPlaceReservableAsWhole,
} from '../../selectors/seating/place'
import {
  setShowPricingOptionsPlace,
  setShowPlaceDescription,
  unselectPlace,
  selectPlace,
} from '../../reducers/seating/mode'
import {isInAccessibilityMode} from '../../selectors/seating/mode'
import {isMobile} from '../../../../../commons/selectors/environment'
import {updatePlace} from '../../reducers/seating/places'
import {validateDonation, validateTicketLimit} from './validation'

interface SelectPlacePricingOption {
  placeId: string
  pricingOptionIds: string[]
  origin: string
}

export const selectPlacePricingOption = createAsyncAction<void, SelectPlacePricingOption>(
  'SELECT_PLACE_PRICING_OPTION',
  async ({placeId, pricingOptionIds, origin}, {getState, dispatch}) => {
    const state = getState()
    const place = getPlace(state, placeId)

    let selectedPricingOptionIds = place.selectedPricingOptionIds
    if (isPlaceArea(place)) {
      selectedPricingOptionIds = [...(selectedPricingOptionIds || []), ...pricingOptionIds]
    } else {
      selectedPricingOptionIds = pricingOptionIds
    }

    dispatch(
      updatePlace({
        place: {
          id: placeId,
          quantity: selectedPricingOptionIds.length,
          selectedPricingOptionIds,
        },
        origin,
      }),
    )
  },
)

interface SetPlaceQuantityArgs {
  placeId: string
  count: number
  origin: string
  validateDonation?: boolean
}

export const setPlaceQuantity = createAsyncAction<void, SetPlaceQuantityArgs>(
  'SET_PLACE_QUANTITY',
  async (
    {placeId, count, validateDonation: shouldValidateDonation = true, origin},
    {getState, dispatch, rejectWithValue},
  ) => {
    const state = getState()
    const place = getPlace(getState(), placeId)
    const {ticket, donation} = place
    const chooseSeatMode = isChooseSeatMode(state)

    if (count > 0 && shouldValidateDonation) {
      const valid = await dispatch(validateDonation({placeId, ticket, donation})).unwrap()

      if (!valid) {
        return rejectWithValue({error: 'Invalid donation'})
      }
    }

    if (count > 0 && isPlaceReservableAsWhole(place)) {
      count = place.places.length
    }

    dispatch(
      updatePlace({
        place: {
          id: placeId,
          quantity: count,
        },
        clearOthers: chooseSeatMode,
        origin,
      }),
    )
  },
)

export const addPlaceDonation = createAsyncAction<void, {placeId: string; donation: string}>(
  'ADD_PLACE_DONATION',
  async ({placeId, donation}, {getState, dispatch, extra: {flowAPI}}) => {
    const t: TFunction = flowAPI.translations.t as TFunction
    const state = getState()
    const place = getPlace(state, placeId)

    dispatch(
      updatePlace({
        place: {
          id: placeId,
          donation,
          ticketPrice: getPlaceTicketPriceText(place.ticket, t, donation),
        },
      }),
    )
  },
)

export const clickPlace = createAsyncAction<void, {place?: Place; origin: string}>(
  'CLICK_PLACE',
  async ({place, origin}, {getState, dispatch}) => {
    const state = getState()
    if (!place) {
      return
    }

    const showAccessibilityMode = isInAccessibilityMode(state)
    const mobile = isMobile(state)
    const selectedPlace = getSelectedPlace(state)
    const currentCount = getTotalPlaceQuantity(getPlaces(state), place.id)
    const area = isPlaceArea(place)
    const placeWithInfo = getAnyPlaceByPlanPlaceId(getPlaces(state), place.id)
    const ticket = placeWithInfo.ticket

    const valid = await dispatch(validateTicketLimit({planPlaceId: place.id})).unwrap()
    if (!valid) {
      return
    }

    const handlePlaceSelectUnselect = () => {
      if (hasPricingOptions(ticket) && !saleScheduled(ticket)) {
        dispatch(setShowPricingOptionsPlace(placeWithInfo))
        return
      }

      selectedPlace?.id === placeWithInfo.id ? dispatch(unselectPlace()) : dispatch(selectPlace(placeWithInfo.id))
    }

    const handleAddPlaceToBasket = () => {
      if (saleScheduled(ticket)) {
        return
      }

      if (hasPricingOptions(ticket) && (!currentCount || area)) {
        dispatch(setShowPricingOptionsPlace(placeWithInfo))
        return
      }

      dispatch(setShowPlaceDescription(null))

      let count: number
      if (area) {
        count = currentCount + 1
      } else {
        count = currentCount ? 0 : 1
      }
      dispatch(
        setPlaceQuantity({
          placeId: place.id,
          count,
          origin,
          validateDonation: showAccessibilityMode,
        }),
      )
    }

    if (!mobile || showAccessibilityMode || currentCount) {
      if (mobile && area) {
        handlePlaceSelectUnselect()
      } else {
        handleAddPlaceToBasket()
      }
    } else {
      handlePlaceSelectUnselect()
    }
  },
)
